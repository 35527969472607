import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

export default function(query, attrs = []){
    return this.query(query, attrs).then(handleQueryResolve, err => {
            console.error('Error :: SQL Query', err);
        })
        .then((records) => {
            try {
                // only if its an array
                if (Array.isArray(records)) {
                    return records.map(record => buildMapToField(record));
                }
                // its an object
                if (Object.keys(records).length) {
                    return buildMapToField(records);
                }
            } catch (e) { // if its none of array or object
                return records;
            }
        },err => {
            console.error('Error :: SQL Records >> ', err);
        });
}